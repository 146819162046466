// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllLanguagesRequest = createAction('GET_ALL_LANGUAGES_REQUEST');
export const getAllLanguagesSuccess = createAction('GET_ALL_LANGUAGES_SUCCESS');
export const getAllLanguagesFailure = createAction('GET_ALL_LANGUAGES_FAILURE');

export const createLanguageRequest = createAction('GET_CREATE_LANGUAGE_REQUEST');
export const createLanguageSuccess = createAction('GET_CREATE_LANGUAGE_SUCCESS');
export const createLanguageFailure = createAction('GET_CREATE_LANGUAGE_FAILURE');
export const resetCreateLanguageState = createAction('RESET_CREATE_LANGUAGE_STATE');

export const updateLanguageRequest = createAction('GET_UPDATE_LANGUAGE_REQUEST');
export const updateLanguageSuccess = createAction('GET_UPDATE_LANGUAGE_SUCCESS');
export const updateLanguageFailure = createAction('GET_UPDATE_LANGUAGE_FAILURE');
export const resetUpdateLanguageState = createAction('RESET_UPDATE_LANGUAGE_STATE');

export const deleteLanguageRequest = createAction('GET_DELETE_LANGUAGE_REQUEST');
export const deleteLanguageSuccess = createAction('GET_DELETE_LANGUAGE_SUCCESS');
export const deleteLanguageFailure = createAction('GET_DELETE_LANGUAGE_FAILURE');
export const resetDeleteLanguageState = createAction('RESET_DELETE_LANGUAGE_STATE');

export const resetLanguageState = createAction('RESET_LANGUAGE_STATE');
