// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllPostsRequest: false,
  isGetAllPostsSuccess: false,
  isGetAllPostsFailure: false,
  getAllPostsState: {},
  //
  isCreatePostRequest: false,
  isCreatePostSuccess: false,
  isCreatePostFailure: false,
  createPostPayloadState: {},
  //
  isUpdatePostRequest: false,
  isUpdatePostSuccess: false,
  isUpdatePostFailure: false,
  //
  isDeletePostRequest: false,
  isDeletePostSuccess: false,
  isDeletePostFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all posts
    [Actions.getAllPostsRequest]: (state) => ({
      ...state,
      isGetAllPostsRequest: true,
      isGetAllPostsSuccess: false,
      isGetAllPostsFailure: false,
    }),
    [Actions.getAllPostsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllPostsRequest: false,
      isGetAllPostsSuccess: true,
      isGetAllPostsFailure: false,
      getAllPostsState: payload,
    }),
    [Actions.getAllPostsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllPostsRequest: false,
      isGetAllPostsSuccess: false,
      isGetAllPostsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create post
    [Actions.createPostRequest]: (state) => ({
      ...state,
      isCreatePostRequest: true,
      isCreatePostSuccess: false,
      isCreatePostFailure: false,
    }),
    [Actions.createPostSuccess]: (state, { payload }) => ({
      ...state,
      isCreatePostRequest: false,
      isCreatePostSuccess: true,
      isCreatePostFailure: false,
      createPostPayloadState: payload,
    }),
    [Actions.createPostFailure]: (state, { payload }) => ({
      ...state,
      isCreatePostRequest: false,
      isCreatePostSuccess: false,
      isCreatePostFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreatePostState]: (state) => ({
      ...state,
      isCreatePostRequest: false,
      isCreatePostSuccess: false,
      isCreatePostFailure: false,
      createPostPayloadState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Update post
    [Actions.updatePostRequest]: (state) => ({
      ...state,
      isUpdatePostRequest: true,
      isUpdatePostSuccess: false,
      isUpdatePostFailure: false,
    }),
    [Actions.updatePostSuccess]: (state) => ({
      ...state,
      isUpdatePostRequest: false,
      isUpdatePostSuccess: true,
      isUpdatePostFailure: false,
    }),
    [Actions.updatePostFailure]: (state, { payload }) => ({
      ...state,
      isUpdatePostRequest: false,
      isUpdatePostSuccess: false,
      isUpdatePostFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdatePostState]: (state) => ({
      ...state,
      isUpdatePostRequest: false,
      isUpdatePostSuccess: false,
      isUpdatePostFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete post
    [Actions.deletePostRequest]: (state) => ({
      ...state,
      isDeletePostRequest: true,
      isDeletePostSuccess: false,
      isDeletePostFailure: false,
    }),
    [Actions.deletePostSuccess]: (state) => ({
      ...state,
      isDeletePostRequest: false,
      isDeletePostSuccess: true,
      isDeletePostFailure: false,
    }),
    [Actions.deletePostFailure]: (state, { payload }) => ({
      ...state,
      isDeletePostRequest: false,
      isDeletePostSuccess: false,
      isDeletePostFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeletePostState]: (state) => ({
      ...state,
      isDeletePostRequest: false,
      isDeletePostSuccess: false,
      isDeletePostFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Reset
    [Actions.resetPostState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
