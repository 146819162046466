// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllLanguagesRequest: false,
  isGetAllLanguagesSuccess: false,
  isGetAllLanguagesFailure: false,
  getAllLanguagesState: {},
  //
  isCreateLanguageRequest: false,
  isCreateLanguageSuccess: false,
  isCreateLanguageFailure: false,
  //
  isUpdateLanguageRequest: false,
  isUpdateLanguageSuccess: false,
  isUpdateLanguageFailure: false,
  //
  isDeleteLanguageRequest: false,
  isDeleteLanguageSuccess: false,
  isDeleteLanguageFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all languages
    [Actions.getAllLanguagesRequest]: (state) => ({
      ...state,
      isGetAllLanguagesRequest: true,
      isGetAllLanguagesSuccess: false,
      isGetAllLanguagesFailure: false,
    }),
    [Actions.getAllLanguagesSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllLanguagesRequest: false,
      isGetAllLanguagesSuccess: true,
      isGetAllLanguagesFailure: false,
      getAllLanguagesState: {
        ...payload,
        data: payload.data.sort((a, b) => (a.sort > b.sort ? 0 : -1)),
      },
    }),
    [Actions.getAllLanguagesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllLanguagesRequest: false,
      isGetAllLanguagesSuccess: false,
      isGetAllLanguagesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create language
    [Actions.createLanguageRequest]: (state) => ({
      ...state,
      isCreateLanguageRequest: true,
      isCreateLanguageSuccess: false,
      isCreateLanguageFailure: false,
    }),
    [Actions.createLanguageSuccess]: (state, { payload }) => ({
      ...state,
      isCreateLanguageRequest: false,
      isCreateLanguageSuccess: true,
      isCreateLanguageFailure: false,
      getAllLanguagesState: {
        ...state.getAllLanguagesState,
        data: [...state.getAllLanguagesState.data, payload],
      },
    }),
    [Actions.createLanguageFailure]: (state, { payload }) => ({
      ...state,
      isCreateLanguageRequest: false,
      isCreateLanguageSuccess: false,
      isCreateLanguageFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateLanguageState]: (state) => ({
      ...state,
      isCreateLanguageRequest: false,
      isCreateLanguageSuccess: false,
      isCreateLanguageFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update language
    [Actions.updateLanguageRequest]: (state) => ({
      ...state,
      isUpdateLanguageRequest: true,
      isUpdateLanguageSuccess: false,
      isUpdateLanguageFailure: false,
    }),
    [Actions.updateLanguageSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateLanguageRequest: false,
      isUpdateLanguageSuccess: true,
      isUpdateLanguageFailure: false,
      getAllLanguagesState: {
        ...state.getAllLanguagesState,
        data: state.getAllLanguagesState.data.map((item) => ({
          ...item,
          default: item.id === payload,
        })),
      },
    }),
    [Actions.updateLanguageFailure]: (state, { payload }) => ({
      ...state,
      isUpdateLanguageRequest: false,
      isUpdateLanguageSuccess: false,
      isUpdateLanguageFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateLanguageState]: (state) => ({
      ...state,
      isUpdateLanguageRequest: false,
      isUpdateLanguageSuccess: false,
      isUpdateLanguageFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete language
    [Actions.deleteLanguageRequest]: (state) => ({
      ...state,
      isDeleteLanguageRequest: true,
      isDeleteLanguageSuccess: false,
      isDeleteLanguageFailure: false,
    }),
    [Actions.deleteLanguageSuccess]: (state, { payload }) => ({
      ...state,
      isDeleteLanguageRequest: false,
      isDeleteLanguageSuccess: true,
      isDeleteLanguageFailure: false,
      getAllLanguagesState: {
        ...state.getAllLanguagesState,
        data: state.getAllLanguagesState.data.filter(
          (item) => item.id !== payload,
        ),
      },
    }),
    [Actions.deleteLanguageFailure]: (state, { payload }) => ({
      ...state,
      isDeleteLanguageRequest: false,
      isDeleteLanguageSuccess: false,
      isDeleteLanguageFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteLanguageState]: (state) => ({
      ...state,
      isDeleteLanguageRequest: false,
      isDeleteLanguageSuccess: false,
      isDeleteLanguageFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Reset
    [Actions.resetLanguageState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
