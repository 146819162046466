// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isSignInRequest: false,
  isSignInSuccess: false,
  isSignInFailure: false,
  userAuthState: {},
  //
  isChangePasswordRequest: false,
  isChangePasswordSuccess: false,
  isChangePasswordFailure: false,
  //
  isRequireChangePasswordRequest: false,
  isRequireChangePasswordSuccess: false,
  isRequireChangePasswordFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Sign in
    [Actions.signInRequest]: (state) => ({
      ...state,
      isSignInRequest: true,
      isSignInSuccess: false,
      isSignInFailure: false,
    }),
    [Actions.signInSuccess]: (state, { payload }) => ({
      ...state,
      isSignInRequest: false,
      isSignInSuccess: true,
      isSignInFailure: false,
      userAuthState: payload,
    }),
    [Actions.signInFailure]: (state, { payload }) => ({
      ...state,
      isSignInRequest: false,
      isSignInSuccess: false,
      isSignInFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Change password
    [Actions.changePasswordRequest]: (state) => ({
      ...state,
      isChangePasswordRequest: true,
      isChangePasswordSuccess: false,
      isChangePasswordFailure: false,
    }),
    [Actions.changePasswordSuccess]: (state) => ({
      ...state,
      isChangePasswordRequest: false,
      isChangePasswordSuccess: true,
      isChangePasswordFailure: false,
    }),
    [Actions.changePasswordFailure]: (state, { payload }) => ({
      ...state,
      isChangePasswordRequest: false,
      isChangePasswordSuccess: false,
      isChangePasswordFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetChangePasswordState]: (state) => ({
      ...state,
      isChangePasswordRequest: false,
      isChangePasswordSuccess: false,
      isChangePasswordFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Require change password
    [Actions.requireChangePasswordRequest]: (state) => ({
      ...state,
      isRequireChangePasswordRequest: true,
      isRequireChangePasswordSuccess: false,
      isRequireChangePasswordFailure: false,
    }),
    [Actions.requireChangePasswordSuccess]: (state) => ({
      ...state,
      isRequireChangePasswordRequest: false,
      isRequireChangePasswordSuccess: true,
      isRequireChangePasswordFailure: false,
    }),
    [Actions.requireChangePasswordFailure]: (state, { payload }) => ({
      ...state,
      isRequireChangePasswordRequest: false,
      isRequireChangePasswordSuccess: false,
      isRequireChangePasswordFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetRequireChangePasswordState]: (state) => ({
      ...state,
      isRequireChangePasswordRequest: false,
      isRequireChangePasswordSuccess: false,
      isRequireChangePasswordFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Reset
    [Actions.resetAuthState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
