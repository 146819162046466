/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllUsersHasRole({ payload = {} }) {
  try {
    const response = yield call(() => axiosMicro.get('/users', {
      params: {
        searchRole: 'has',
        include: 'roles',
        ...payload,
      },
    }));
    yield put(Actions.getAllUsersHasRoleSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllUsersHasRoleFailure(messages));
    }
  }
}

function* getAllUsersNoRole({ payload = {} }) {
  try {
    const response = yield call(() => axiosMicro.get('/users', {
      params: {
        searchRole: 'no',
        ...payload,
      },
    }));
    yield put(Actions.getAllUsersNoRoleSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllUsersNoRoleFailure(messages));
    }
  }
}

function* syncUserRoles({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/roles/sync', payload));
    yield put(Actions.syncUserRolesSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.syncUserRolesFailure(messages));
    }
  }
}

function* revokeRolesFromUser({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/roles/revoke', payload));
    yield put(Actions.revokeRolesFromUserSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.revokeRolesFromUserFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllUsersHasRoleRequest, getAllUsersHasRole);
  yield takeLatest(Actions.getAllUsersNoRoleRequest, getAllUsersNoRole);
  yield takeLatest(Actions.syncUserRolesRequest, syncUserRoles);
  yield takeLatest(Actions.revokeRolesFromUserRequest, revokeRolesFromUser);
}
