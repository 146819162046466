/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllMenus({ payload = {} }) {
  try {
    const response = yield call(() => axiosMicro.get('/menus', {
      params: payload,
    }));
    yield put(Actions.getAllMenusSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllMenusFailure(messages));
    }
  }
}

function* createMenu({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/menus', payload));
    yield put(Actions.createMenuSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createMenuFailure(messages));
    }
  }
}

function* updateMenu({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/menus/${payload.id}`, payload.body));
    yield put(Actions.updateMenuSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateMenuFailure(messages));
    }
  }
}

function* deleteMenu({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/menus/${payload}`));
    yield put(Actions.deleteMenuSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteMenuFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllMenusRequest, getAllMenus);
  yield takeLatest(Actions.createMenuRequest, createMenu);
  yield takeLatest(Actions.updateMenuRequest, updateMenu);
  yield takeLatest(Actions.deleteMenuRequest, deleteMenu);
}
