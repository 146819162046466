/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getArticleGroups({ payload = {} }) {
  try {
    const response = yield call(() => axiosMicro.get('/groups', {
      params: {
        style: 'article',
        ...payload,
      },
    }));
    yield put(Actions.getArticleGroupsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getArticleGroupsFailure(messages));
    }
  }
}

function* getFieldGroups({ payload = {} }) {
  try {
    const response = yield call(() => axiosMicro.get('/groups', {
      params: {
        style: 'field',
        include: 'fields',
        ...payload,
      },
    }));
    yield put(Actions.getFieldGroupsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getFieldGroupsFailure(messages));
    }
  }
}

function* getTrademarkGroups({ payload = {} }) {
  try {
    const response = yield call(() => axiosMicro.get('/groups', {
      params: {
        style: 'trademark',
        ...payload,
      },
    }));
    yield put(Actions.getTrademarkGroupsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getTrademarkGroupsFailure(messages));
    }
  }
}

function* createGroup({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/groups?include=type,fields', payload));
    yield put(Actions.createGroupSuccess(response.data.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createGroupFailure(messages));
    }
  }
}

function* updateGroup({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/groups/${payload.id}?include=type,fields`, payload.body));
    yield put(Actions.updateGroupSuccess(response.data.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateGroupFailure(messages));
    }
  }
}

function* deleteGroup({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/groups/${payload.id}`));
    yield put(Actions.deleteGroupSuccess(payload));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteGroupFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getArticleGroupsRequest, getArticleGroups);
  yield takeLatest(Actions.getFieldGroupsRequest, getFieldGroups);
  yield takeLatest(Actions.getTrademarkGroupsRequest, getTrademarkGroups);
  yield takeLatest(Actions.createGroupRequest, createGroup);
  yield takeLatest(Actions.updateGroupRequest, updateGroup);
  yield takeLatest(Actions.deleteGroupRequest, deleteGroup);
}
