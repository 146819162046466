/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllWarehouses({ payload = {} }) {
  try {
    const response = yield call(() => axiosMicro.get('/warehouses', {
      params: payload,
    }));
    yield put(Actions.getAllWarehousesSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllWarehousesFailure(messages));
    }
  }
}

function* getWarehouseProducts({ payload = {} }) {
  try {
    const response = yield call(() => axiosMicro.get('/warehouses/products', {
      params: payload,
    }));
    yield put(Actions.getWarehouseProductsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getWarehouseProductsFailure(messages));
    }
  }
}

function* getWarehouseProductHistory({ payload = {} }) {
  try {
    const response = yield call(() => axiosMicro.get('/warehouses/change/items', {
      params: payload,
    }));
    yield put(Actions.getWarehouseProductHistorySuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getWarehouseProductHistoryFailure(messages));
    }
  }
}

function* createWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/warehouses', payload));
    yield put(Actions.createWarehouseSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createWarehouseFailure(messages));
    }
  }
}

function* updateWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/warehouses/${payload.id}`, payload.body));
    yield put(Actions.updateWarehouseSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateWarehouseFailure(messages));
    }
  }
}

function* deleteWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/warehouses/${payload}`));
    yield put(Actions.deleteWarehouseSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteWarehouseFailure(messages));
    }
  }
}

function* getWarehouseChanges({ payload = {} }) {
  try {
    const response = yield call(() => axiosMicro.get('/warehouses/changes', {
      params: payload,
    }));
    yield put(Actions.getWarehouseChangesSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getWarehouseChangesFailure(messages));
    }
  }
}

function* createWarehouseChange({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post(`/warehouses/${payload.warehouse_id}/change`, payload.payload));
    yield put(Actions.createWarehouseChangeSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createWarehouseChangeFailure(messages));
    }
  }
}

function* cancelWarehouseChange({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post(`/warehouses/change/${payload}/cancel`));
    yield put(Actions.cancelWarehouseChangeSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.cancelWarehouseChangeFailure(messages));
    }
  }
}

function* getAllSuppliers({ payload = {} }) {
  try {
    const response = yield call(() => axiosMicro.get('/suppliers', {
      params: payload,
    }));
    yield put(Actions.getAllSuppliersSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllSuppliersFailure(messages));
    }
  }
}

function* createSupplier({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/suppliers', payload));
    yield put(Actions.createSupplierSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createSupplierFailure(messages));
    }
  }
}

function* updateSupplier({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/suppliers/${payload.id}`, payload.body));
    yield put(Actions.updateSupplierSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateSupplierFailure(messages));
    }
  }
}

function* deleteSupplier({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/suppliers/${payload}`));
    yield put(Actions.deleteSupplierSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteSupplierFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllWarehousesRequest, getAllWarehouses);
  yield takeLatest(Actions.createWarehouseRequest, createWarehouse);
  yield takeLatest(Actions.updateWarehouseRequest, updateWarehouse);
  yield takeLatest(Actions.deleteWarehouseRequest, deleteWarehouse);
  yield takeLatest(Actions.getWarehouseProductsRequest, getWarehouseProducts);
  yield takeLatest(Actions.getWarehouseProductHistoryRequest, getWarehouseProductHistory);
  //
  yield takeLatest(Actions.getWarehouseChangesRequest, getWarehouseChanges);
  yield takeLatest(Actions.createWarehouseChangeRequest, createWarehouseChange);
  yield takeLatest(Actions.cancelWarehouseChangeRequest, cancelWarehouseChange);
  //
  yield takeLatest(Actions.getAllSuppliersRequest, getAllSuppliers);
  yield takeLatest(Actions.createSupplierRequest, createSupplier);
  yield takeLatest(Actions.updateSupplierRequest, updateSupplier);
  yield takeLatest(Actions.deleteSupplierRequest, deleteSupplier);
}
