import { useSelector } from 'react-redux';

import './LoadingPage.scss';

export const LoadingPage = () => {
  const {
    getAllSettingsState,
  } = useSelector((store) => store.settings);

  return (
    <div id="loading">
      <div className="loading-inner">
        {
          getAllSettingsState?.data?.find((item) => item?.key === 'site_logo')?.value && (
            <img
              src={getAllSettingsState.data.find((item) => item.key === 'site_logo').value}
              alt="Logo"
              style={{ width: '125px', height: 'auto', display: 'block' }}
            />
          )
        }
        <div className="loading-progress" />
      </div>
    </div>
  );
};
