export const defaultMenuType = 'menu-default';
export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light';
export const colorDark = 'dark';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const defaultPasswordReset = 'Satek123@';
export const defaultFavicon = '/favicon.ico';
export const defaultLogo = '/logo.png';

const languages = process.env.REACT_APP_LANGUAGES.split(',');
export const defaultLocale = process.env.REACT_APP_LOCALE;
export const localeOptions = languages.map((lang) => (
  {
    id: lang,
    name: `common.lang-options.${lang}`,
  }
));
