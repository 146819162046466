// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllOrdersRequest: false,
  isGetAllOrdersSuccess: false,
  isGetAllOrdersFailure: false,
  getAllOrdersState: {},
  //
  isCreateOrderRequest: false,
  isCreateOrderSuccess: false,
  isCreateOrderFailure: false,
  //
  isUpdateOrderRequest: false,
  isUpdateOrderSuccess: false,
  isUpdateOrderFailure: false,
  //
  isDeleteOrderRequest: false,
  isDeleteOrderSuccess: false,
  isDeleteOrderFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all orders
    [Actions.getAllOrdersRequest]: (state) => ({
      ...state,
      isGetAllOrdersRequest: true,
      isGetAllOrdersSuccess: false,
      isGetAllOrdersFailure: false,
    }),
    [Actions.getAllOrdersSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllOrdersRequest: false,
      isGetAllOrdersSuccess: true,
      isGetAllOrdersFailure: false,
      getAllOrdersState: payload,
    }),
    [Actions.getAllOrdersFailure]: (state, { payload }) => ({
      ...state,
      isGetAllOrdersRequest: false,
      isGetAllOrdersSuccess: false,
      isGetAllOrdersFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create order
    [Actions.createOrderRequest]: (state) => ({
      ...state,
      isCreateOrderRequest: true,
      isCreateOrderSuccess: false,
      isCreateOrderFailure: false,
    }),
    [Actions.createOrderSuccess]: (state, { payload }) => ({
      ...state,
      isCreateOrderRequest: false,
      isCreateOrderSuccess: true,
      isCreateOrderFailure: false,
      getAllOrdersState:
        state.getAllOrdersState?.meta?.pagination?.current_page === 1
          ? {
            ...state.getAllOrdersState,
            data:
                state.getAllOrdersState.meta.pagination.count
                === state.getAllOrdersState.meta.pagination.per_page
                  ? [payload.data].concat(
                    state.getAllOrdersState.data.slice(
                      0,
                      state.getAllOrdersState.data.length - 1,
                    ),
                  )
                  : [payload.data].concat(state.getAllOrdersState.data),
            meta: {
              ...state.getAllOrdersState.meta,
              pagination: {
                ...state.getAllOrdersState.meta.pagination,
                count:
                    state.getAllOrdersState.meta.pagination.count
                    === state.getAllOrdersState.meta.pagination.per_page
                      ? state.getAllOrdersState.meta.pagination.per_page
                      : state.getAllOrdersState.meta.pagination.count + 1,
                total: state.getAllOrdersState.meta.pagination.total + 1,
                total_pages: Math.ceil(
                  (state.getAllOrdersState.meta.pagination.total + 1)
                      / state.getAllOrdersState.meta.pagination.per_page,
                ),
              },
            },
          }
          : { ...state.getAllOrdersState },
    }),
    [Actions.createOrderFailure]: (state, { payload }) => ({
      ...state,
      isCreateOrderRequest: false,
      isCreateOrderSuccess: false,
      isCreateOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateOrderState]: (state) => ({
      ...state,
      isCreateOrderRequest: false,
      isCreateOrderSuccess: false,
      isCreateOrderFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update order
    [Actions.updateOrderRequest]: (state) => ({
      ...state,
      isUpdateOrderRequest: true,
      isUpdateOrderSuccess: false,
      isUpdateOrderFailure: false,
    }),
    [Actions.updateOrderSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateOrderRequest: false,
      isUpdateOrderSuccess: true,
      isUpdateOrderFailure: false,
      getAllOrdersState: {
        ...state.getAllOrdersState,
        data: state.getAllOrdersState.data.map((item) => item.id === payload.data.id ? payload.data : item),
      },
    }),
    [Actions.updateOrderFailure]: (state, { payload }) => ({
      ...state,
      isUpdateOrderRequest: false,
      isUpdateOrderSuccess: false,
      isUpdateOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateOrderState]: (state) => ({
      ...state,
      isUpdateOrderRequest: false,
      isUpdateOrderSuccess: false,
      isUpdateOrderFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete order
    [Actions.deleteOrderRequest]: (state) => ({
      ...state,
      isDeleteOrderRequest: true,
      isDeleteOrderSuccess: false,
      isDeleteOrderFailure: false,
    }),
    [Actions.deleteOrderSuccess]: (state) => ({
      ...state,
      isDeleteOrderRequest: false,
      isDeleteOrderSuccess: true,
      isDeleteOrderFailure: false,
    }),
    [Actions.deleteOrderFailure]: (state, { payload }) => ({
      ...state,
      isDeleteOrderRequest: false,
      isDeleteOrderSuccess: false,
      isDeleteOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteOrderState]: (state) => ({
      ...state,
      isDeleteOrderRequest: false,
      isDeleteOrderSuccess: false,
      isDeleteOrderFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Reset
    [Actions.resetOrderState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
