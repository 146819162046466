const comment = {
  'admin.comment.hetmet': 'Bình luận',
  'admin.comment.breadcrumb.heading': 'Danh sách các bình luận',
  'admin.comment.breadcrumb.parent': 'Bình luận',
  'admin.comment.column.author': 'Tác giả',
  'admin.comment.column.comment': 'Bình luận',
  'admin.comment.label.message': 'Nội dung',
  'admin.comment.error.message': 'Vui lòng nhập nội dung!',
  'admin.comment.column.reply-for': 'Trả lời cho',
  'admin.comment.column.created_at': 'Đã đăng vào',
  'admin.comment.reply-for': 'Trả lời tới',
  'admin.comment.noti.reply-comment.success': 'Trả lời bình luận thành công!',
  'admin.comment.noti.reply-comment.failure': 'Trả lời bình luận không thành công! Vui lòng thử lại sau.',
  'admin.comment.noti.update-comment.success': 'Chỉnh sửa bình luận thành công!',
  'admin.comment.noti.update-comment.failure': 'Chỉnh sửa bình luận không thành công! Vui lòng thử lại sau.',
  'admin.comment.noti.delete-comment.success': 'Xoá bình luận thành công!',
  'admin.comment.noti.delete-comment.failure': 'Xoá bình luận không thành công! Vui lòng thử lại sau.',
  'admin.comment.modal.action.title.reply': 'Trả lời tới $x',
  'admin.comment.modal.action.title.update': 'Chỉnh sửa',
  'admin.comment.modal.action.btn.reply': 'Trả lời',
  'admin.comment.modal.action.btn.update': 'Cập nhật bình luận',
  'admin.comment.modal.delete.title': 'Xác nhận xoá bình luận!',
  'admin.comment.modal.delete.description': 'Bạn chắc chắn muốn xoá bình luận này?',
};

export default {
  ...comment,
};
