const appearance = {};

const menus = {
  'admin.appearance.menus.hetmet': 'Menu - Giao diện',
  'admin.appearance.menus.breadcrumb.heading': 'Menu',
  'admin.appearance.menus.breadcrumb.parent': 'Giao diện',
  //
  'admin.appearance.loading-title': 'Đang khởi tạo menu',
  'admin.appearance.title-select-menu': 'Chọn menu để sửa',
  'admin.appearance.title-or': 'hoặc',
  'admin.appearance.link-create-new-menu': 'tạo menu mới',
  'admin.appearance.section-failure.error-loading-menu':
    'Có lỗi xảy ra khi khởi tạo Menu, vui lòng thử lại sau!',
  'admin.appearance.section-failure.error-create-menu':
    'Có lỗi xảy ra khi tạo Menu, vui lòng thử lại sau!',
  'admin.appearance.section-failure.error-save-menu':
    'Có lỗi xảy ra khi lưu thay đổi Menu, vui lòng thử lại sau!',
  'admin.appearance.menu-frame-left.title': 'Thêm liên kết',
  'admin.appearance.menu-frame-left.menu-settings-column.custom-link.title':
    'Liên kết tự tạo',
  'admin.appearance.menu-frame-left.menu-settings-column.custom-link.form-group.label.link':
    'URL',
  'admin.appearance.menu-frame-left.menu-settings-column.custom-link.form-group.label.title':
    'Tên đường dẫn',
  'admin.appearance.menu-frame-left.menu-settings-column.custom-link.form-group.label.target':
    'Kiểu',
  'admin.appearance.menu-frame-left.menu-settings-column.custom-link.form-group.label.description':
    'Mô tả',
  'admin.appearance.menu-frame-left.menu-settings-column.custom-link.form-group.label.className':
    'Custom Class',
  'admin.appearance.menu-frame-left.menu-settings-column.custom-link.form-group.label.user':
    'Người xem',
  'admin.appearance.menu-frame-left.menu-settings-column.custom-link.form-group.label.user.auth':
    'Đã đăng nhập',
  'admin.appearance.menu-frame-left.menu-settings-column.custom-link.form-group.label.user.guest':
    'Chưa đăng nhập',
  'admin.appearance.menu-frame-left.menu-settings-column.custom-link.form-group.label.custom':
    'Custom Data',
  'admin.appearance.menu-frame-left.menu-settings-column.custom-link.button.add-menu':
    'Thêm vào menu',
  'admin.appearance.menu-frame-left.menu-settings-column.pages.title': 'Loại bài đăng',
  'admin.appearance.menu-frame-left.menu-settings-column.category-post.title': 'Danh mục',
  'admin.appearance.menu-frame-left.menu-settings-column.group-post.title': 'Nhóm tin',
  'admin.appearance.menu-frame-left.menu-settings-column.trademark.title': 'Thương hiệu',
  'admin.appearance.menu-frame-left.menu-settings-column.post.title': 'Bài viết',
  'admin.appearance.menu-frame-left.menu-settings-column.product.title': 'Sản phẩm',
  'admin.appearance.menu-frame-right.title': 'Cấu trúc menu',
  'admin.appearance.menu-frame-right.menu-management-column.label.key': 'Khóa',
  'admin.appearance.menu-frame-right.menu-management-column.label.title':
    'Tên menu',
  'admin.appearance.menu-frame-right.menu-management-column.subtitle-first-create-menu':
    'Đặt tên cho menu, sau đó nhấp chuột vào tạo menu.',
  'admin.appearance.menu-frame-right.menu-management-column.btn.create-menu':
    'Tạo menu',
  'admin.appearance.menu-frame-right.menu-management-column.btn.save-menu':
    'Lưu menu',
  'admin.appearance.menu-frame-right.menu-management-column.btn.remove-menu':
    'Xóa menu',
  'admin.appearance.menu-frame-right.tree-menu.empty-title':
    'Thêm các mục từ cột bên trái.',
  'admin.appearance.menu-frame-right.tree-menu.btn.remove-menu-item': 'Xóa bỏ',
  'admin.appearance.menu-frame-right.tree-menu.btn.save-changes-menu-item':
    'Lưu',
  'admin.appearance.modal.confirm-remove-menu.title': 'Xác nhận xóa menu!',
  'admin.appearance.modal.confirm-remove-menu.sub-title':
    'Bạn chắc chắn muốn xóa menu này?',
  'admin.appearance.noti.create-menu.title.success': 'Thêm menu thành công!',
  'admin.appearance.noti.save-menu.title.success': 'Lưu menu thành công!',
  'admin.appearance.noti.remove-menu.title.success': 'Xóa menu thành công!',
  'admin.appearance.noti.remove-menu.title.failure':
    'Xóa menu thất bại, vui lòng thử lại sau!',
  'admin.appearance.customLinkTargetSelectList.self':
    'Mở trang trên tab hiện tại',
  'admin.appearance.customLinkTargetSelectList.blank':
    'Chuyển trang sang tab mới',
};

const slider = {
  'admin.appearance.slider.hetmet': 'Slider - Giao diện',
  'admin.appearance.slider.breadcrumb.heading': 'Slider',
  'admin.appearance.slider.breadcrumb.parent': 'Giao diện',
  'admin.appearance.slider.btn.add-new': 'Thêm Slider',
  'admin.appearance.slider.noti.create-slider.title.success':
    'Tạo slider thành công!',
  'admin.appearance.slider.noti.create-slider.title.failure':
    'Tạo slider không thành công! Vui lòng thử lại sau.',
  'admin.appearance.slider.noti.update-slider.title.success':
    'Cập nhật slider thành công!',
  'admin.appearance.slider.noti.update-slider.title.failure':
    'Cập nhật slider không thành công! Vui lòng thử lại sau.',
  'admin.appearance.slider.noti.delete-slider.title.success':
    'Xóa slider thành công!',
  'admin.appearance.slider.noti.delete-slider.title.failure':
    'Xóa slider không thành công! Vui lòng thử lại sau.',
  //
  'admin.appearance.slider.modal.add.title': 'Thêm slider',
  'admin.appearance.slider.modal.add.label.key': 'Khóa',
  'admin.appearance.slider.modal.add.label.title': 'Tiêu đề',
  'admin.appearance.slider.modal.add.label.items': 'Danh sách ảnh',
  'admin.appearance.slider.modal.add.label.image': 'Hình ảnh',
  'admin.appearance.slider.modal.add.label.item-title': 'Tiêu đề',
  'admin.appearance.slider.modal.add.label.url': 'Liên kết',
  'admin.appearance.slider.modal.add.label.description': 'Mô tả',
  'admin.appearance.slider.modal.add.btn.upload-image': 'Tải ảnh mới',
  'admin.appearance.slider.modal.add.btn.add-slider': 'Thêm ảnh Slider',
  //
  'admin.appearance.slider.modal.details.title': 'Chi tiết slider',
  //
  'admin.appearance.slider.modal.update.title': 'Cập nhật slider',
  //
  'admin.appearance.slider.modal.delete.title': 'Xác nhận xóa slider!',
  'admin.appearance.slider.modal.delete.description':
    'Bạn có chắc chắn muốn xóa slider này?',
};

export default {
  ...appearance,
  ...menus,
  ...slider,
};
