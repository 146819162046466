import { all } from 'redux-saga/effects';

import agency from './agency/saga';
import auth from './auth/saga';
import category from './category/saga';
import comment from './comment/saga';
import discount from './discount/saga';
import feedback from './feedback/saga';
import field from './field/saga';
import group from './group/saga';
import language from './language/saga';
import media from './media/saga';
import member from './member/saga';
import menuItem from './menuItem/saga';
import order from './order/saga';
import permission from './permission/saga';
import post from './post/saga';
import rating from './rating/saga';
import role from './role/saga';
import seo from './seo/saga';
import settings from './settings/saga';
import slider from './slider/saga';
import type from './type/saga';
import user from './user/saga';
import warehouse from './warehouse/saga';
import widgets from './widget/saga';

export default function* rootSaga() {
  yield all([
    agency(),
    auth(),
    category(),
    comment(),
    discount(),
    feedback(),
    field(),
    group(),
    language(),
    media(),
    member(),
    menuItem(),
    order(),
    permission(),
    post(),
    rating(),
    role(),
    seo(),
    settings(),
    slider(),
    type(),
    user(),
    warehouse(),
    widgets(),
  ]);
}
