/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllLanguages() {
  try {
    const response = yield call(() => axiosMicro.get('/languages'));
    yield put(Actions.getAllLanguagesSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllLanguagesFailure(messages));
    }
  }
}

function* createLanguage({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/languages', payload));
    yield put(Actions.createLanguageSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createLanguageFailure(messages));
    }
  }
}

function* updateLanguage({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/languages/${payload}/default`));
    yield put(Actions.updateLanguageSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateLanguageFailure(messages));
    }
  }
}

function* deleteLanguage({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/languages/${payload}`));
    yield put(Actions.deleteLanguageSuccess(payload));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteLanguageFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllLanguagesRequest, getAllLanguages);
  yield takeLatest(Actions.createLanguageRequest, createLanguage);
  yield takeLatest(Actions.updateLanguageRequest, updateLanguage);
  yield takeLatest(Actions.deleteLanguageRequest, deleteLanguage);
}
