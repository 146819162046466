// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllRatingsRequest: false,
  isGetAllRatingsSuccess: false,
  isGetAllRatingsFailure: false,
  getAllRatingsState: {},
  //
  isCreateRatingRequest: false,
  isCreateRatingSuccess: false,
  isCreateRatingFailure: false,
  //
  isDeleteRatingRequest: false,
  isDeleteRatingSuccess: false,
  isDeleteRatingFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all ratings
    [Actions.getAllRatingsRequest]: (state) => ({
      ...state,
      isGetAllRatingsRequest: true,
      isGetAllRatingsSuccess: false,
      isGetAllRatingsFailure: false,
    }),
    [Actions.getAllRatingsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllRatingsRequest: false,
      isGetAllRatingsSuccess: true,
      isGetAllRatingsFailure: false,
      getAllRatingsState: payload,
    }),
    [Actions.getAllRatingsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllRatingsRequest: false,
      isGetAllRatingsSuccess: false,
      isGetAllRatingsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create rating
    [Actions.createRatingRequest]: (state) => ({
      ...state,
      isCreateRatingRequest: true,
      isCreateRatingSuccess: false,
      isCreateRatingFailure: false,
    }),
    [Actions.createRatingSuccess]: (state, { payload }) => ({
      ...state,
      isCreateRatingRequest: false,
      isCreateRatingSuccess: true,
      isCreateRatingFailure: false,
      getAllRatingsState:
        state.getAllRatingsState?.meta?.pagination?.current_page === 1
          ? {
            ...state.getAllRatingsState,
            data:
                state.getAllRatingsState.meta.pagination.count
                === state.getAllRatingsState.meta.pagination.per_page
                  ? [payload.data].concat(
                    state.getAllRatingsState.data.slice(
                      0,
                      state.getAllRatingsState.data.length - 1,
                    ),
                  )
                  : [payload.data].concat(state.getAllRatingsState.data),
            meta: {
              ...state.getAllRatingsState.meta,
              pagination: {
                ...state.getAllRatingsState.meta.pagination,
                count:
                    state.getAllRatingsState.meta.pagination.count
                    === state.getAllRatingsState.meta.pagination.per_page
                      ? state.getAllRatingsState.meta.pagination.per_page
                      : state.getAllRatingsState.meta.pagination.count + 1,
                total: state.getAllRatingsState.meta.pagination.total + 1,
                total_pages: Math.ceil(
                  (state.getAllRatingsState.meta.pagination.total + 1)
                      / state.getAllRatingsState.meta.pagination.per_page,
                ),
              },
            },
          }
          : { ...state.getAllRatingsState },
    }),
    [Actions.createRatingFailure]: (state, { payload }) => ({
      ...state,
      isCreateRatingRequest: false,
      isCreateRatingSuccess: false,
      isCreateRatingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateRatingState]: (state) => ({
      ...state,
      isCreateRatingRequest: false,
      isCreateRatingSuccess: false,
      isCreateRatingFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete rating
    [Actions.deleteRatingRequest]: (state) => ({
      ...state,
      isDeleteRatingRequest: true,
      isDeleteRatingSuccess: false,
      isDeleteRatingFailure: false,
    }),
    [Actions.deleteRatingSuccess]: (state) => ({
      ...state,
      isDeleteRatingRequest: false,
      isDeleteRatingSuccess: true,
      isDeleteRatingFailure: false,
    }),
    [Actions.deleteRatingFailure]: (state, { payload }) => ({
      ...state,
      isDeleteRatingRequest: false,
      isDeleteRatingSuccess: false,
      isDeleteRatingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteRatingState]: (state) => ({
      ...state,
      isDeleteRatingRequest: false,
      isDeleteRatingSuccess: false,
      isDeleteRatingFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Reset
    [Actions.resetRatingState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
