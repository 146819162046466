/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllAgencies({ payload = {} }) {
  try {
    const response = yield call(() => axiosMicro.get('/agencies', {
      params: payload,
    }));
    yield put(Actions.getAllAgenciesSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllAgenciesFailure(messages));
    }
  }
}

function* createAgency({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/agencies', payload));
    yield put(Actions.createAgencySuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createAgencyFailure(messages));
    }
  }
}

function* updateAgency({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/agencies/${payload.id}`, payload.body));
    yield put(Actions.updateAgencySuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateAgencyFailure(messages));
    }
  }
}

function* deleteAgency({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/agencies/${payload}`));
    yield put(Actions.deleteAgencySuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteAgencyFailure(messages));
    }
  }
}

function* attachUsersToAgency({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/agencies/attach', payload));
    yield put(Actions.attachUsersToAgencySuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.attachUsersToAgencyFailure(messages));
    }
  }
}

function* detachUsersToAgency({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/agencies/detach', payload));
    yield put(Actions.detachUsersToAgencySuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.detachUsersToAgencyFailure(messages));
    }
  }
}

function* getUsersAgency({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/agencies/${payload.id}/users`, {
      params: payload.params || {},
    }));
    yield put(Actions.getUsersAgencySuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getUsersAgencyFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllAgenciesRequest, getAllAgencies);
  yield takeLatest(Actions.createAgencyRequest, createAgency);
  yield takeLatest(Actions.updateAgencyRequest, updateAgency);
  yield takeLatest(Actions.deleteAgencyRequest, deleteAgency);
  yield takeLatest(Actions.attachUsersToAgencyRequest, attachUsersToAgency);
  yield takeLatest(Actions.detachUsersToAgencyRequest, detachUsersToAgency);
  yield takeLatest(Actions.getUsersAgencyRequest, getUsersAgency);
}
