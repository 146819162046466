import { combineReducers } from 'redux';

import agency from './agency/reducer';
import auth from './auth/reducer';
import category from './category/reducer';
import comment from './comment/reducer';
import discount from './discount/reducer';
import feedback from './feedback/reducer';
import field from './field/reducer';
import general from './general/reducer';
import group from './group/reducer';
import language from './language/reducer';
import media from './media/reducer';
import member from './member/reducer';
import menu from './menu/reducer';
import menuItem from './menuItem/reducer';
import order from './order/reducer';
import permission from './permission/reducer';
import post from './post/reducer';
import rating from './rating/reducer';
import role from './role/reducer';
import seo from './seo/reducer';
import settings from './settings/reducer';
import slider from './slider/reducer';
import type from './type/reducer';
import user from './user/reducer';
import warehouse from './warehouse/reducer';
import widgets from './widget/reducer';

const rootReducer = combineReducers({
  agency,
  auth,
  category,
  comment,
  discount,
  feedback,
  field,
  general,
  group,
  language,
  media,
  member,
  menu,
  menuItem,
  order,
  permission,
  post,
  rating,
  role,
  seo,
  settings,
  slider,
  type,
  user,
  warehouse,
  widgets,
});

export default rootReducer;
