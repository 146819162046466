import en from './locales/en';
import vi from './locales/vi';
import ja from './locales/ja';

export const AppLocale = {
  vi: {
    locale: 'vi',
    messages: vi,
  },
  en: {
    locale: 'en',
    messages: en,
  },
  ja: {
    locale: 'ja',
    messages: ja,
  },
};
