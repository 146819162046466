import { useEffect } from 'react';

import { translate } from '~/helpers/utils';

export const Helmet = ({
  title, titleEntire, isTranslate = true, children,
}) => {
  let titleTrans = '';
  let titleEntireTrans = '';

  if (isTranslate) {
    if (title) {
      titleTrans = translate(title);
    }

    if (titleEntire) {
      titleEntireTrans = translate(titleEntire);
    }
  } else {
    titleTrans = title;
    titleEntireTrans = titleEntire;
  }

  const NAME = 'Satek';

  document.title = titleEntireTrans
    || (titleTrans ? `${titleTrans} | ${NAME}` : `${NAME}`);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <>{children}</>;
};
