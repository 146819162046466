const seo = {
  'admin.seo.hetmet': 'Quản lý SEO',
  'admin.seo.breadcrumb.heading': 'Quản lý SEO',
  'admin.seo.breadcrumb.parent': 'SEO',
  'admin.seo.btn.add': 'Thêm SEO',
  'admin.seo.noti.create-seo.success': 'Thêm SEO thành công!',
  'admin.seo.noti.create-seo.failure': 'Thêm SEO thất bại! Vui lòng thử lại sau.',
  'admin.seo.noti.update-seo.success': 'Cập nhật SEO thành công!',
  'admin.seo.noti.update-seo.failure': 'Cập nhật SEO thất bại! Vui lòng thử lại sau.',
  'admin.seo.noti.delete-seo.success': 'Xóa SEO thành công',
  'admin.seo.noti.delete-seo.failure': 'Xóa SEO thất bại! Vui lòng thử lại sau.',
  'admin.seo.modal.action.title.create': 'Thêm mới',
  'admin.seo.modal.action.title.update': 'Cập nhật',
  'admin.seo.modal.action.title.details': 'Chi tiết',
  'admin.seo.modal.action.btn.create': 'Thêm',
  'admin.seo.modal.action.btn.update': 'Cập nhật',
  'admin.seo.modal.action.label.url': 'Đường dẫn',
  'admin.seo.modal.action.label.type': 'Loại',
  'admin.seo.modal.action.label.title': 'Tiêu đề',
  'admin.seo.modal.action.label.image': 'Hình ảnh',
  'admin.seo.modal.action.label.description': 'Mô tả',
  'admin.seo.modal.action.label.keyword': 'Từ khóa',
  'admin.seo.modal.delete.title': 'Xác nhận xóa SEO!',
  'admin.seo.modal.delete.description': 'Bạn có chắc chắn muốn xóa SEO này?',
  'admin.seo.column.url': 'Đường dẫn',
  'admin.seo.column.type': 'Loại',
  'admin.seo.column.title': 'Tiêu đề',
  'admin.seo.type.item.website': 'Website',
  'admin.seo.type.item.article': 'Bài viết',
  'admin.seo.type.item.profile': 'Profile',
  'admin.seo.type.item.book': 'Sách',
  'admin.seo.error-msg.url': 'Vui lòng điền trường này!',
  'admin.seo.error-msg.type': 'Vui lòng điền trường này!',
  'admin.seo.error-msg.title': 'Vui lòng điền trường này!',
  'admin.seo.error-msg.image': 'Vui lòng điền trường này!',
};

export default {
  ...seo,
};
