import { AppLocale } from '~/configs/langs';
import { getCurrentLanguage } from './common';

export const translate = (id) => {
  const currentLang = getCurrentLanguage();
  const { messages } = AppLocale[currentLang];

  if (id) {
    return messages[id];
  }

  return '';
};
