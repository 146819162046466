export const PERMISSIONS = {
  MEDIA: {
    GET_ALL_MEDIA: 'list-media',
    CREATE_MEDIA: 'create-media',
    UPDATE_MEDIA: 'update-media',
    DELETE_MEDIA: 'delete-media',
  },
  POST: {
    GET_ALL_POSTS: 'list-posts',
    CREATE_POST: 'create-posts',
    UPDATE_POST: 'update-posts',
    DELETE_POST: 'delete-posts',
  },
  CATEGORY: {
    GET_ALL_CATEGORIES: 'list-categories',
    CREATE_CATEGORY: 'create-categories',
    UPDATE_CATEGORY: 'update-categories',
    DELETE_CATEGORY: 'delete-categories',
  },
  GROUP: {
    GET_ALL_GROUPS: 'list-groups',
    CREATE_GROUP: 'create-groups',
    UPDATE_GROUP: 'update-groups',
    DELETE_GROUP: 'delete-groups',
  },
  FIELD: {
    GET_ALL_FIELDS: 'list-fields',
    CREATE_FIELD: 'create-fields',
    UPDATE_FIELD: 'update-fields',
    DELETE_FIELD: 'delete-fields',
  },
  DISCOUNT: {
    GET_ALL_DISCOUNTS: 'list-discounts',
    CREATE_DISCOUNT: 'create-discounts',
    UPDATE_DISCOUNT: 'update-discounts',
    DELETE_DISCOUNT: 'delete-discounts',
  },
  ORDER: {
    GET_ALL_ORDERS: 'list-orders',
    UPDATE_ORDER: 'update-orders',
    DELETE_ORDER: 'delete-orders',
  },
  TYPE: {
    GET_ALL_TYPES: 'list-types',
    CREATE_TYPE: 'create-types',
    UPDATE_TYPE: 'update-types',
    DELETE_TYPE: 'delete-types',
  },
  USER: {
    GET_ALL_USERS: 'list-users',
    CREATE_USER: 'create-users',
    UPDATE_USER: 'update-users',
    DELETE_USER: 'delete-users',
  },
  ROLE: {
    GET_ALL_ROLES: 'manage-roles',
    GET_ALL_PERMISSIONS: 'manage-roles',
    SYNC_USER_ROLES: 'manage-admins-access',
    REVOKE_ROLES_FROM_USER: 'manage-admins-access',
    SYNC_PERMISSIONS_ON_ROLE: 'manage-roles',
    MANAGE_PERMISSIONS: 'manage-permissions',
    CREATE_ROLE: 'manage-roles',
    DELETE_ROLE: 'manage-roles',
  },
  FEEDBACK: {
    GET_ALL_FEEDBACK: 'list-feedback',
    UPDATE_FEEDBACK: 'update-feedback',
    DELETE_FEEDBACK: 'delete-feedback',
  },
  SEO: {
    GET_ALL_SEO: 'list-seos',
    CREATE_SEO: 'create-seos',
    UPDATE_SEO: 'update-seos',
    DELETE_SEO: 'delete-seos',
  },
  SETTING: {
    GET_ALL_SETTING: 'list-settings',
    CREATE_SETTING: 'create-settings',
    UPDATE_SETTING: 'update-settings',
    DELETE_SETTING: 'delete-settings',
    MANAGE_REDIRECT: 'manage-redirects',
  },
  SLIDER: {
    GET_ALL_SLIDER: 'list-sliders',
    CREATE_SLIDER: 'create-sliders',
    UPDATE_SLIDER: 'update-sliders',
    DELETE_SLIDER: 'delete-sliders',
  },
  MENU: {
    GET_ALL_MENU: 'list-menus',
    CREATE_MENU: 'create-menus',
    UPDATE_MENU: 'update-menus',
    DELETE_MENU: 'delete-menus',
  },
  WAREHOUSE: {
    MANAGE_WAREHOUSE: 'manage-warehouses',
    EDIT_WAREHOUSE: 'edit-warehouses',
  },
  SUPPLIER: {
    GET_ALL_SUPPLIER: 'list-suppliers',
    CREATE_SUPPLIER: 'create-suppliers',
    UPDATE_SUPPLIER: 'update-suppliers',
    DELETE_SUPPLIER: 'delete-suppliers',
  },
  WIDGET: {
    GET_ALL_WIDGET: 'list-widgets',
    CREATE_WIDGET: 'create-widgets',
    UPDATE_WIDGET: 'update-widgets',
    DELETE_WIDGET: 'delete-widgets',
  },
  COMMENT: {
    GET_ALL_COMMENT: 'list-comments',
    UPDATE_COMMENT: 'update-comments',
    DELETE_COMMENT: 'delete-comments',
  },
  RATING: {
    GET_ALL_RATINGS: 'list-ratings',
    DELETE_RATING: 'delete-ratings',
  },
  AGENCY: {
    GET_ALL_AGENCIES: 'list-agencies',
    CREATE_AGENCY: 'create-agencies',
    UPDATE_AGENCY: 'update-agencies',
    DELETE_AGENCY: 'delete-agencies',
  },
  LANGUAGE: {
    MANAGE_LANGUAGE: 'manage-languages',
  },
};
