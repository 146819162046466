// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllTypesRequest: false,
  isGetAllTypesSuccess: false,
  isGetAllTypesFailure: false,
  getAllTypesState: {},
  //
  isCreateTypeRequest: false,
  isCreateTypeSuccess: false,
  isCreateTypeFailure: false,
  //
  isUpdateTypeRequest: false,
  isUpdateTypeSuccess: false,
  isUpdateTypeFailure: false,
  //
  isDeleteTypeRequest: false,
  isDeleteTypeSuccess: false,
  isDeleteTypeFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all types
    [Actions.getAllTypesRequest]: (state) => ({
      ...state,
      isGetAllTypesRequest: true,
      isGetAllTypesSuccess: false,
      isGetAllTypesFailure: false,
    }),
    [Actions.getAllTypesSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllTypesRequest: false,
      isGetAllTypesSuccess: true,
      isGetAllTypesFailure: false,
      getAllTypesState: payload,
    }),
    [Actions.getAllTypesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllTypesRequest: false,
      isGetAllTypesSuccess: false,
      isGetAllTypesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create type
    [Actions.createTypeRequest]: (state) => ({
      ...state,
      isCreateTypeRequest: true,
      isCreateTypeSuccess: false,
      isCreateTypeFailure: false,
    }),
    [Actions.createTypeSuccess]: (state, { payload }) => ({
      ...state,
      isCreateTypeRequest: false,
      isCreateTypeSuccess: true,
      isCreateTypeFailure: false,
      getAllTypesState: {
        ...state.getAllTypesState,
        data: [...state.getAllTypesState.data, payload.data],
      },
    }),
    [Actions.createTypeFailure]: (state, { payload }) => ({
      ...state,
      isCreateTypeRequest: false,
      isCreateTypeSuccess: false,
      isCreateTypeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateTypeState]: (state) => ({
      ...state,
      isCreateTypeRequest: false,
      isCreateTypeSuccess: false,
      isCreateTypeFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update type
    [Actions.updateTypeRequest]: (state) => ({
      ...state,
      isUpdateTypeRequest: true,
      isUpdateTypeSuccess: false,
      isUpdateTypeFailure: false,
    }),
    [Actions.updateTypeSuccess]: (state) => ({
      ...state,
      isUpdateTypeRequest: false,
      isUpdateTypeSuccess: true,
      isUpdateTypeFailure: false,
    }),
    [Actions.updateTypeFailure]: (state, { payload }) => ({
      ...state,
      isUpdateTypeRequest: false,
      isUpdateTypeSuccess: false,
      isUpdateTypeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateTypeState]: (state) => ({
      ...state,
      isUpdateTypeRequest: false,
      isUpdateTypeSuccess: false,
      isUpdateTypeFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete type
    [Actions.deleteTypeRequest]: (state) => ({
      ...state,
      isDeleteTypeRequest: true,
      isDeleteTypeSuccess: false,
      isDeleteTypeFailure: false,
    }),
    [Actions.deleteTypeSuccess]: (state, { payload }) => ({
      ...state,
      isDeleteTypeRequest: false,
      isDeleteTypeSuccess: true,
      isDeleteTypeFailure: false,
      getAllTypesState: {
        ...state.getAllTypesState,
        data: [...state.getAllTypesState.data.filter((item) => item.id !== payload)],
      },
    }),
    [Actions.deleteTypeFailure]: (state, { payload }) => ({
      ...state,
      isDeleteTypeRequest: false,
      isDeleteTypeSuccess: false,
      isDeleteTypeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteTypeState]: (state) => ({
      ...state,
      isDeleteTypeRequest: false,
      isDeleteTypeSuccess: false,
      isDeleteTypeFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Reset
    [Actions.resetTypeState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
