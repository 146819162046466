// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllDiscountsRequest: false,
  isGetAllDiscountsSuccess: false,
  isGetAllDiscountsFailure: false,
  getAllDiscountsState: {},
  //
  isCreateDiscountRequest: false,
  isCreateDiscountSuccess: false,
  isCreateDiscountFailure: false,
  //
  isUpdateDiscountRequest: false,
  isUpdateDiscountSuccess: false,
  isUpdateDiscountFailure: false,
  //
  isDeleteDiscountRequest: false,
  isDeleteDiscountSuccess: false,
  isDeleteDiscountFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all discounts
    [Actions.getAllDiscountsRequest]: (state) => ({
      ...state,
      isGetAllDiscountsRequest: true,
      isGetAllDiscountsSuccess: false,
      isGetAllDiscountsFailure: false,
    }),
    [Actions.getAllDiscountsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllDiscountsRequest: false,
      isGetAllDiscountsSuccess: true,
      isGetAllDiscountsFailure: false,
      getAllDiscountsState: payload,
    }),
    [Actions.getAllDiscountsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllDiscountsRequest: false,
      isGetAllDiscountsSuccess: false,
      isGetAllDiscountsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create discount
    [Actions.createDiscountRequest]: (state) => ({
      ...state,
      isCreateDiscountRequest: true,
      isCreateDiscountSuccess: false,
      isCreateDiscountFailure: false,
    }),
    [Actions.createDiscountSuccess]: (state, { payload }) => ({
      ...state,
      isCreateDiscountRequest: false,
      isCreateDiscountSuccess: true,
      isCreateDiscountFailure: false,
      getAllDiscountsState:
        state.getAllDiscountsState?.meta?.pagination?.current_page === 1
          ? {
            ...state.getAllDiscountsState,
            data:
                state.getAllDiscountsState.meta.pagination.count
                === state.getAllDiscountsState.meta.pagination.per_page
                  ? [payload.data].concat(
                    state.getAllDiscountsState.data.slice(
                      0,
                      state.getAllDiscountsState.data.length - 1,
                    ),
                  )
                  : [payload.data].concat(state.getAllDiscountsState.data),
            meta: {
              ...state.getAllDiscountsState.meta,
              pagination: {
                ...state.getAllDiscountsState.meta.pagination,
                count:
                    state.getAllDiscountsState.meta.pagination.count
                    === state.getAllDiscountsState.meta.pagination.per_page
                      ? state.getAllDiscountsState.meta.pagination.per_page
                      : state.getAllDiscountsState.meta.pagination.count + 1,
                total: state.getAllDiscountsState.meta.pagination.total + 1,
                total_pages: Math.ceil(
                  (state.getAllDiscountsState.meta.pagination.total + 1)
                      / state.getAllDiscountsState.meta.pagination.per_page,
                ),
              },
            },
          }
          : { ...state.getAllDiscountsState },
    }),
    [Actions.createDiscountFailure]: (state, { payload }) => ({
      ...state,
      isCreateDiscountRequest: false,
      isCreateDiscountSuccess: false,
      isCreateDiscountFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateDiscountState]: (state) => ({
      ...state,
      isCreateDiscountRequest: false,
      isCreateDiscountSuccess: false,
      isCreateDiscountFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update discount
    [Actions.updateDiscountRequest]: (state) => ({
      ...state,
      isUpdateDiscountRequest: true,
      isUpdateDiscountSuccess: false,
      isUpdateDiscountFailure: false,
    }),
    [Actions.updateDiscountSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateDiscountRequest: false,
      isUpdateDiscountSuccess: true,
      isUpdateDiscountFailure: false,
      getAllDiscountsState: {
        ...state.getAllDiscountsState,
        data: state.getAllDiscountsState.data.map((item) => item.id === payload.data.id ? payload.data : item),
      },
    }),
    [Actions.updateDiscountFailure]: (state, { payload }) => ({
      ...state,
      isUpdateDiscountRequest: false,
      isUpdateDiscountSuccess: false,
      isUpdateDiscountFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateDiscountState]: (state) => ({
      ...state,
      isUpdateDiscountRequest: false,
      isUpdateDiscountSuccess: false,
      isUpdateDiscountFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete discount
    [Actions.deleteDiscountRequest]: (state) => ({
      ...state,
      isDeleteDiscountRequest: true,
      isDeleteDiscountSuccess: false,
      isDeleteDiscountFailure: false,
    }),
    [Actions.deleteDiscountSuccess]: (state) => ({
      ...state,
      isDeleteDiscountRequest: false,
      isDeleteDiscountSuccess: true,
      isDeleteDiscountFailure: false,
    }),
    [Actions.deleteDiscountFailure]: (state, { payload }) => ({
      ...state,
      isDeleteDiscountRequest: false,
      isDeleteDiscountSuccess: false,
      isDeleteDiscountFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteDiscountState]: (state) => ({
      ...state,
      isDeleteDiscountRequest: false,
      isDeleteDiscountSuccess: false,
      isDeleteDiscountFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Reset
    [Actions.resetDiscountState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
