// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllPostsRequest = createAction('GET_ALL_POSTS_REQUEST');
export const getAllPostsSuccess = createAction('GET_ALL_POSTS_SUCCESS');
export const getAllPostsFailure = createAction('GET_ALL_POSTS_FAILURE');

export const createPostRequest = createAction('CREATE_POST_REQUEST');
export const createPostSuccess = createAction('CREATE_POST_SUCCESS');
export const createPostFailure = createAction('CREATE_POST_FAILURE');
export const resetCreatePostState = createAction('RESET_CREATE_POST_STATE');

export const updatePostRequest = createAction('UPDATE_POST_REQUEST');
export const updatePostSuccess = createAction('UPDATE_POST_SUCCESS');
export const updatePostFailure = createAction('UPDATE_POST_FAILURE');
export const resetUpdatePostState = createAction('RESET_UPDATE_POST_STATE');

export const deletePostRequest = createAction('DELETE_POST_REQUEST');
export const deletePostSuccess = createAction('DELETE_POST_SUCCESS');
export const deletePostFailure = createAction('DELETE_POST_FAILURE');
export const resetDeletePostState = createAction('RESET_DELETE_POST_STATE');

export const resetPostState = createAction('RESET_POST_STATE');
