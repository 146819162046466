// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  getArticleGroupsState: {},
  isGetArticleGroupsRequest: false,
  isGetArticleGroupsSuccess: false,
  isGetArticleGroupsFailure: false,
  //
  getFieldGroupsState: {},
  isGetFieldGroupsRequest: false,
  isGetFieldGroupsSuccess: false,
  isGetFieldGroupsFailure: false,
  //
  getTrademarkGroupsState: {},
  isGetTrademarkGroupsRequest: false,
  isGetTrademarkGroupsSuccess: false,
  isGetTrademarkGroupsFailure: false,
  //
  isCreateGroupRequest: false,
  isCreateGroupSuccess: false,
  isCreateGroupFailure: false,
  //
  isUpdateGroupRequest: false,
  isUpdateGroupSuccess: false,
  isUpdateGroupFailure: false,
  //
  isDeleteGroupRequest: false,
  isDeleteGroupSuccess: false,
  isDeleteGroupFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get article groups
    [Actions.getArticleGroupsRequest]: (state) => ({
      ...state,
      isGetArticleGroupsRequest: true,
      isGetArticleGroupsSuccess: false,
      isGetArticleGroupsFailure: false,
    }),
    [Actions.getArticleGroupsSuccess]: (state, { payload }) => ({
      ...state,
      isGetArticleGroupsRequest: false,
      isGetArticleGroupsSuccess: true,
      isGetArticleGroupsFailure: false,
      getArticleGroupsState: payload,
    }),
    [Actions.getArticleGroupsFailure]: (state, { payload }) => ({
      ...state,
      isGetArticleGroupsRequest: false,
      isGetArticleGroupsSuccess: false,
      isGetArticleGroupsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get field groups
    [Actions.getFieldGroupsRequest]: (state) => ({
      ...state,
      isGetFieldGroupsRequest: true,
      isGetFieldGroupsSuccess: false,
      isGetFieldGroupsFailure: false,
    }),
    [Actions.getFieldGroupsSuccess]: (state, { payload }) => ({
      ...state,
      isGetFieldGroupsRequest: false,
      isGetFieldGroupsSuccess: true,
      isGetFieldGroupsFailure: false,
      getFieldGroupsState: payload,
    }),
    [Actions.getFieldGroupsFailure]: (state, { payload }) => ({
      ...state,
      isGetFieldGroupsRequest: false,
      isGetFieldGroupsSuccess: false,
      isGetFieldGroupsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get trademark groups
    [Actions.getTrademarkGroupsRequest]: (state) => ({
      ...state,
      isGetTrademarkGroupsRequest: true,
      isGetTrademarkGroupsSuccess: false,
      isGetTrademarkGroupsFailure: false,
    }),
    [Actions.getTrademarkGroupsSuccess]: (state, { payload }) => ({
      ...state,
      isGetTrademarkGroupsRequest: false,
      isGetTrademarkGroupsSuccess: true,
      isGetTrademarkGroupsFailure: false,
      getTrademarkGroupsState: payload,
    }),
    [Actions.getTrademarkGroupsFailure]: (state, { payload }) => ({
      ...state,
      isGetTrademarkGroupsRequest: false,
      isGetTrademarkGroupsSuccess: false,
      isGetTrademarkGroupsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create group
    [Actions.createGroupRequest]: (state) => ({
      ...state,
      isCreateGroupRequest: true,
      isCreateGroupSuccess: false,
      isCreateGroupFailure: false,
    }),
    [Actions.createGroupSuccess]: (state, { payload }) => {
      const key = {
        article: 'getArticleGroupsState',
        field: 'getFieldGroupsState',
        trademark: 'getTrademarkGroupsState',
      }[payload.style];
      return {
        ...state,
        isCreateGroupRequest: false,
        isCreateGroupSuccess: true,
        isCreateGroupFailure: false,
        [key]: {
          ...state[key],
          data: [...state[key].data, payload],
        },
      };
    },
    [Actions.createGroupFailure]: (state, { payload }) => ({
      ...state,
      isCreateGroupRequest: false,
      isCreateGroupSuccess: false,
      isCreateGroupFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateGroupState]: (state) => ({
      ...state,
      isCreateGroupRequest: false,
      isCreateGroupSuccess: false,
      isCreateGroupFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update group
    [Actions.updateGroupRequest]: (state) => ({
      ...state,
      isUpdateGroupRequest: true,
      isUpdateGroupSuccess: false,
      isUpdateGroupFailure: false,
    }),
    [Actions.updateGroupSuccess]: (state, { payload }) => {
      const key = {
        article: 'getArticleGroupsState',
        field: 'getFieldGroupsState',
        trademark: 'getTrademarkGroupsState',
      }[payload.style];

      return {
        ...state,
        isUpdateGroupRequest: false,
        isUpdateGroupSuccess: true,
        isUpdateGroupFailure: false,
        [key]: {
          ...state[key],
          data: [...state[key].data.map((item) => item.id === payload.id ? payload : item)],
        },
      };
    },
    [Actions.updateGroupFailure]: (state, { payload }) => ({
      ...state,
      isUpdateGroupRequest: false,
      isUpdateGroupSuccess: false,
      isUpdateGroupFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateGroupState]: (state) => ({
      ...state,
      isUpdateGroupRequest: false,
      isUpdateGroupSuccess: false,
      isUpdateGroupFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete group
    [Actions.deleteGroupRequest]: (state) => ({
      ...state,
      isDeleteGroupRequest: true,
      isDeleteGroupSuccess: false,
      isDeleteGroupFailure: false,
    }),
    [Actions.deleteGroupSuccess]: (state, { payload }) => {
      const key = {
        article: 'getArticleGroupsState',
        field: 'getFieldGroupsState',
        trademark: 'getTrademarkGroupsState',
      }[payload.style];

      return {
        ...state,
        isDeleteGroupRequest: false,
        isDeleteGroupSuccess: true,
        isDeleteGroupFailure: false,
        [key]: {
          ...state[key],
          data: [...state[key].data.filter((item) => item.id !== payload.id)],
        },
      };
    },
    [Actions.deleteGroupFailure]: (state, { payload }) => ({
      ...state,
      isDeleteGroupRequest: false,
      isDeleteGroupSuccess: false,
      isDeleteGroupFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteGroupState]: (state) => ({
      ...state,
      isDeleteGroupRequest: false,
      isDeleteGroupSuccess: false,
      isDeleteGroupFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Reset
    [Actions.resetGroupState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
