// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllCategoriesRequest: false,
  isGetAllCategoriesSuccess: false,
  isGetAllCategoriesFailure: false,
  getAllCategoriesState: {},
  //
  isCreateCategoryRequest: false,
  isCreateCategorySuccess: false,
  isCreateCategoryFailure: false,
  //
  isUpdateCategoryRequest: false,
  isUpdateCategorySuccess: false,
  isUpdateCategoryFailure: false,
  //
  isDeleteCategoryRequest: false,
  isDeleteCategorySuccess: false,
  isDeleteCategoryFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all categories
    [Actions.getAllCategoriesRequest]: (state) => ({
      ...state,
      isGetAllCategoriesRequest: true,
      isGetAllCategoriesSuccess: false,
      isGetAllCategoriesFailure: false,
    }),
    [Actions.getAllCategoriesSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllCategoriesRequest: false,
      isGetAllCategoriesSuccess: true,
      isGetAllCategoriesFailure: false,
      getAllCategoriesState: {
        ...payload,
        data: payload.data
          .sort((a, b) => (a.order > b.order ? 0 : -1))
          .map((item) => ({
            ...item,
            titleCustom:
              item.level === 1
                ? item.title
                : [...new Array(item.level)]
                  .map(() => '—')
                  .concat(item.title)
                  .join(' '),
          })),
      },
    }),
    [Actions.getAllCategoriesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllCategoriesRequest: false,
      isGetAllCategoriesSuccess: false,
      isGetAllCategoriesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create category
    [Actions.createCategoryRequest]: (state) => ({
      ...state,
      isCreateCategoryRequest: true,
      isCreateCategorySuccess: false,
      isCreateCategoryFailure: false,
    }),
    [Actions.createCategorySuccess]: (state) => ({
      ...state,
      isCreateCategoryRequest: false,
      isCreateCategorySuccess: true,
      isCreateCategoryFailure: false,
    }),
    [Actions.createCategoryFailure]: (state, { payload }) => ({
      ...state,
      isCreateCategoryRequest: false,
      isCreateCategorySuccess: false,
      isCreateCategoryFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateCategoryState]: (state) => ({
      ...state,
      isCreateCategoryRequest: false,
      isCreateCategorySuccess: false,
      isCreateCategoryFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update category
    [Actions.updateCategoryRequest]: (state) => ({
      ...state,
      isUpdateCategoryRequest: true,
      isUpdateCategorySuccess: false,
      isUpdateCategoryFailure: false,
    }),
    [Actions.updateCategorySuccess]: (state) => ({
      ...state,
      isUpdateCategoryRequest: false,
      isUpdateCategorySuccess: true,
      isUpdateCategoryFailure: false,
    }),
    [Actions.updateCategoryFailure]: (state, { payload }) => ({
      ...state,
      isUpdateCategoryRequest: false,
      isUpdateCategorySuccess: false,
      isUpdateCategoryFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateCategoryState]: (state) => ({
      ...state,
      isUpdateCategoryRequest: false,
      isUpdateCategorySuccess: false,
      isUpdateCategoryFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete category
    [Actions.deleteCategoryRequest]: (state) => ({
      ...state,
      isDeleteCategoryRequest: true,
      isDeleteCategorySuccess: false,
      isDeleteCategoryFailure: false,
    }),
    [Actions.deleteCategorySuccess]: (state) => ({
      ...state,
      isDeleteCategoryRequest: false,
      isDeleteCategorySuccess: true,
      isDeleteCategoryFailure: false,
    }),
    [Actions.deleteCategoryFailure]: (state, { payload }) => ({
      ...state,
      isDeleteCategoryRequest: false,
      isDeleteCategorySuccess: false,
      isDeleteCategoryFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteCategoryState]: (state) => ({
      ...state,
      isDeleteCategoryRequest: false,
      isDeleteCategorySuccess: false,
      isDeleteCategoryFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Reset
    [Actions.resetCategoryState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
