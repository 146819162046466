// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetAllFeedbacksRequest: false,
  isGetAllFeedbacksSuccess: false,
  isGetAllFeedbacksFailure: false,
  getAllFeedbacksState: {},
  //
  isCreateFeedbackRequest: false,
  isCreateFeedbackSuccess: false,
  isCreateFeedbackFailure: false,
  //
  isUpdateFeedbackRequest: false,
  isUpdateFeedbackSuccess: false,
  isUpdateFeedbackFailure: false,
  //
  isDeleteFeedbackRequest: false,
  isDeleteFeedbackSuccess: false,
  isDeleteFeedbackFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get all feedbacks
    [Actions.getAllFeedbacksRequest]: (state) => ({
      ...state,
      isGetAllFeedbacksRequest: true,
      isGetAllFeedbacksSuccess: false,
      isGetAllFeedbacksFailure: false,
    }),
    [Actions.getAllFeedbacksSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllFeedbacksRequest: false,
      isGetAllFeedbacksSuccess: true,
      isGetAllFeedbacksFailure: false,
      getAllFeedbacksState: payload,
    }),
    [Actions.getAllFeedbacksFailure]: (state, { payload }) => ({
      ...state,
      isGetAllFeedbacksRequest: false,
      isGetAllFeedbacksSuccess: false,
      isGetAllFeedbacksFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create feedback
    [Actions.createFeedbackRequest]: (state) => ({
      ...state,
      isCreateFeedbackRequest: true,
      isCreateFeedbackSuccess: false,
      isCreateFeedbackFailure: false,
    }),
    [Actions.createFeedbackSuccess]: (state, { payload }) => ({
      ...state,
      isCreateFeedbackRequest: false,
      isCreateFeedbackSuccess: true,
      isCreateFeedbackFailure: false,
      getAllFeedbacksState:
        state.getAllFeedbacksState?.meta?.pagination?.current_page === 1
          ? {
            ...state.getAllFeedbacksState,
            data:
                state.getAllFeedbacksState.meta.pagination.count
                === state.getAllFeedbacksState.meta.pagination.per_page
                  ? [payload.data].concat(
                    state.getAllFeedbacksState.data.slice(
                      0,
                      state.getAllFeedbacksState.data.length - 1,
                    ),
                  )
                  : [payload.data].concat(state.getAllFeedbacksState.data),
            meta: {
              ...state.getAllFeedbacksState.meta,
              pagination: {
                ...state.getAllFeedbacksState.meta.pagination,
                count:
                    state.getAllFeedbacksState.meta.pagination.count
                    === state.getAllFeedbacksState.meta.pagination.per_page
                      ? state.getAllFeedbacksState.meta.pagination.per_page
                      : state.getAllFeedbacksState.meta.pagination.count + 1,
                total: state.getAllFeedbacksState.meta.pagination.total + 1,
                total_pages: Math.ceil(
                  (state.getAllFeedbacksState.meta.pagination.total + 1)
                      / state.getAllFeedbacksState.meta.pagination.per_page,
                ),
              },
            },
          }
          : { ...state.getAllFeedbacksState },
    }),
    [Actions.createFeedbackFailure]: (state, { payload }) => ({
      ...state,
      isCreateFeedbackRequest: false,
      isCreateFeedbackSuccess: false,
      isCreateFeedbackFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateFeedbackState]: (state) => ({
      ...state,
      isCreateFeedbackRequest: false,
      isCreateFeedbackSuccess: false,
      isCreateFeedbackFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update discount
    [Actions.updateFeedbackRequest]: (state) => ({
      ...state,
      isUpdateFeedbackRequest: true,
      isUpdateFeedbackSuccess: false,
      isUpdateFeedbackFailure: false,
    }),
    [Actions.updateFeedbackSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateFeedbackRequest: false,
      isUpdateFeedbackSuccess: true,
      isUpdateFeedbackFailure: false,
      getAllFeedbacksState: {
        ...state.getAllFeedbacksState,
        data: state.getAllFeedbacksState.data.map((item) => item.id === payload.data.id ? payload.data : item),
      },
    }),
    [Actions.updateFeedbackFailure]: (state, { payload }) => ({
      ...state,
      isUpdateFeedbackRequest: false,
      isUpdateFeedbackSuccess: false,
      isUpdateFeedbackFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateFeedbackState]: (state) => ({
      ...state,
      isUpdateFeedbackRequest: false,
      isUpdateFeedbackSuccess: false,
      isUpdateFeedbackFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete feedback
    [Actions.deleteFeedbackRequest]: (state) => ({
      ...state,
      isDeleteFeedbackRequest: true,
      isDeleteFeedbackSuccess: false,
      isDeleteFeedbackFailure: false,
    }),
    [Actions.deleteFeedbackSuccess]: (state) => ({
      ...state,
      isDeleteFeedbackRequest: false,
      isDeleteFeedbackSuccess: true,
      isDeleteFeedbackFailure: false,
    }),
    [Actions.deleteFeedbackFailure]: (state, { payload }) => ({
      ...state,
      isDeleteFeedbackRequest: false,
      isDeleteFeedbackSuccess: false,
      isDeleteFeedbackFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteFeedbackState]: (state) => ({
      ...state,
      isDeleteFeedbackRequest: false,
      isDeleteFeedbackSuccess: false,
      isDeleteFeedbackFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Reset
    [Actions.resetFeedbackState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
